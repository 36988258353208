var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('h2',[_vm._v("All Clients")])]),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.clients,"fields":_vm.fields,"striped":""},scopedSlots:_vm._u([{key:"isActive",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('CBadge',{attrs:{"color":_vm.getBadgeColor(item.isActive)}},[_vm._v(_vm._s(_vm.getBadgeLabel(item.isActive)))])],1)]}},{key:"clientCode",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('router-link',{attrs:{"to":{ name: 'view-client', params: { name: item.clientCode } }}},[_vm._v(_vm._s(item.clientCode))])],1)]}},{key:"clientName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_c('router-link',{attrs:{"to":{ name: 'view-client', params: { name: item.clientCode } }}},[_vm._v(_vm._s(item.clientName))])],1)]}},{key:"projectName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(item.projectName))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_c('CButtonGroup',[_c('CButton',{on:{"click":function($event){return _vm.showWorkflows(item)}}},[_c('MIcon',{attrs:{"name":"fa-play"}})],1),_c('CButton',{on:{"click":function($event){return _vm.showOverview(item)}}},[_c('MIcon',{attrs:{"name":"fa-dashboard"}})],1),_c('CButton',{on:{"click":function($event){return _vm.showAssign(item)}}},[_c('MIcon',{attrs:{"name":"fa-users"}})],1),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.doDelete(item)}}},[_c('MIcon',{attrs:{"name":"fa-trash"}})],1)],1)],1)]}}])})],1)],1),_c('CModal',{ref:"wfModal",attrs:{"show":_vm.showWorkflowModal,"client":_vm.currentClient},on:{"update:show":[function($event){_vm.showWorkflowModal=$event},_vm.handleCloseWorkflows]},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Start New Workflow")])]},proxy:true},{key:"footer",fn:function(){return [_c('CButtonToolbar',[_c('CButton',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$refs.wfModal.$emit('update:show', false)}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"primary","disabled":!_vm.currentWorkflow},on:{"click":function($event){return _vm.$refs.wfModal.$emit('update:show', false, { value: 'start' })}}},[_vm._v("Start")])],1)]},proxy:true}])},[[_c('CSelect',{attrs:{"label":"Choose Workflow:","options":_vm.workflows,"value":_vm.currentWorkflow,"placeholder":"Choose Workflow"},on:{"update:value":function($event){_vm.currentWorkflow=$event}}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }