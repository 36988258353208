<template>
  <div>
    <CCard>
      <CCardHeader>
        <h2>All Clients</h2>
      </CCardHeader>
      <CCardBody>
        <CDataTable :items="clients" :fields="fields" striped>
          <template #isActive="{ item }">
            <td class="align-middle">
              <CBadge :color="getBadgeColor(item.isActive)">{{
                getBadgeLabel(item.isActive)
              }}</CBadge>
            </td>
          </template>
          <template #clientCode="{ item }">
            <td class="align-middle">
              <router-link
                :to="{ name: 'view-client', params: { name: item.clientCode } }"
                >{{ item.clientCode }}</router-link
              >
            </td>
          </template>
          <template #clientName="{ item }">
            <td class="align-middle">
              <router-link
                :to="{ name: 'view-client', params: { name: item.clientCode } }"
                >{{ item.clientName }}</router-link
              >
            </td>
          </template>
          <template #projectName="{ item }">
            <td class="align-middle">{{ item.projectName }}</td>
          </template>
          <template #actions="{ item }">
            <td class="text-right">
              <CButtonGroup>
                <CButton @click="showWorkflows(item)">
                  <MIcon name="fa-play" />
                </CButton>
                <CButton @click="showOverview(item)">
                  <MIcon name="fa-dashboard" />
                </CButton>
                <CButton @click="showAssign(item)">
                  <MIcon name="fa-users" />
                </CButton>
                <CButton color="danger" @click="doDelete(item)">
                  <MIcon name="fa-trash" />
                </CButton>
              </CButtonGroup>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CModal
      ref="wfModal"
      :show.sync="showWorkflowModal"
      :client="currentClient"
      @update:show="handleCloseWorkflows"
    >
      <template #header>
        <h2>Start New Workflow</h2>
      </template>
      <template>
        <CSelect
          label="Choose Workflow:"
          :options="workflows"
          :value.sync="currentWorkflow"
          placeholder="Choose Workflow"
        />
      </template>
      <template #footer>
        <CButtonToolbar>
          <CButton
            class="mr-2"
            color="secondary"
            @click="$refs.wfModal.$emit('update:show', false)"
            >Cancel</CButton
          >
          <CButton
            color="primary"
            :disabled="!currentWorkflow"
            @click="
              $refs.wfModal.$emit('update:show', false, { value: 'start' })
            "
            >Start</CButton
          >
        </CButtonToolbar>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { entityLoadingMixin } from '@/mixins';

const FIELDS = [
  {
    key: 'isActive',
    label: 'Status',
  },
  {
    key: 'clientCode',
    label: 'Code',
  },
  {
    key: 'clientName',
    label: 'Name',
  },
  {
    key: 'projectName',
    label: 'Project',
  },
  {
    key: 'actions',
    label: '',
  },
];

export default {
  name: 'ListClients',
  mixins: [entityLoadingMixin],
  computed: {
    ...mapGetters('clients', ['clientsResults']),
    clients() {
      return this.clientsResults;
    },
    fields() {
      return FIELDS;
    },
    workflows() {
      let wfList = [];
      if (this.workflowList) {
        this.workflowList.forEach((wf) => {
          wfList.push(wf.name);
        });
      }
      return wfList;
    },
  },
  methods: {
    getBadgeColor(status) {
      switch (status) {
        case 'N':
          return 'danger';
        default:
          return 'success';
      }
    },
    getBadgeLabel(status) {
      switch (status) {
        case 'N':
          return 'INACTIVE';
        default:
          return 'ACTIVE';
      }
    },
    showOverview(client) {
      this.$router.push({
        name: 'view-client',
        params: { name: client.clientCode },
      });
    },
    showAssign(client) {
      this.$router.push({
        name: 'admin-assign',
        params: { name: client.clientCode },
      });
    },
    showWorkflows(client) {
      this.currentClient = client;
      this.$store
        .dispatch('workflows/fetchWorkflowDefinitions', true)
        .then((result) => {
          this.workflowList = result;
          this.showWorkflowModal = true;
        })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Unable to fetch the list of Workflows in the system.',
          });
        });
    },
    handleCloseWorkflows(v, e) {
      if (e && e.value === 'start') {
        this.$store
          .dispatch('clients/startWorkflow', {
            code: this.currentClient.clientCode,
            workflow: this.currentWorkflow,
          })
          .then((result) => {
            this.$swal.fire({
              icon: 'success',
              text: 'Workflow has been successfully started!',
              title: 'Workflow Started',
            });
          })
          .catch((err) => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Something happened and we could not start the workflow.',
            });
          });
      }
    },
    doDelete(client) {
      this.$swal
        .fire({
          title: 'Confirm',
          text: 'Are you sure you wish to delete ' + client.clientCode + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Delete',
        })
        .then((result) => {
          if (result.value) {
            this.$store.dispatch('clients/delete', client).then((res) => {
              this.$swal
                .fire({
                  title: 'Deleted',
                  text: 'The client has been deleted successfully!',
                  icon: 'success',
                })
                .then(() => {
                  this.$router.push({ name: 'dashboard', params: {} });
                })
                .catch((error) => {
                  this.$swal.fire({
                    title: 'Error',
                    text:
                      'An error occurred which prevented the deletion of your client:' +
                      error,
                    icon: 'error',
                  });
                });
            });
          }
        });
    },
  },
  data() {
    return {
      dispatchPath: 'clients/fetchClients',
      showWorkflowModal: false,
      currentClient: null,
      currentWorkflow: null,
      workflowList: null,
    };
  },
};
</script>
